/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/settings/billing/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var SettingsBillingController;

import Ember from 'ember';

import config from '../../../config/environment';

SettingsBillingController = Ember.Controller.extend({
    cdn: Ember.inject.service(),
    Collector: Ember.inject.service('collector-service'),
    intl: Em.inject.service(),
    month: Ember.computed(function () {
        return moment().format('YYYY-MM-DD');
    }),
    oldMonths: Em.computed('month', function () {
        var i, j, month, months;
        month = this.get('month');
        months = [];
        for (i = j = 1; j < 4; i = ++j) {
            months.push(moment(month).subtract(i, 'month').format('YYYY-MM-DD'));
        }
        return months;
    }),
    terms_url: Em.computed(function () {
        var cdn;
        cdn = this.get('cdn');
        switch (moment.locale()) {
            case 'fi':
                return config.termsAndConditions.fi;
            case 'sv':
            case 'se':
                return config.termsAndConditions.se;
            default:
                return config.termsAndConditions.en;
        }
    }),
    products: Em.computed(function () {
        var billing_desc, c, products, t;
        c = this.get('Collector');
        t = this.get('intl');
        products = c.get('products');
        billing_desc = [];
        products.forEach(function (product) {
            if (
                t.exists('billing.products.description.' + product) &&
                t.exists('billing.products.title.' + product)
            ) {
                return billing_desc.push({
                    desc: t.t('billing.products.description.' + product),
                    name: t.t('billing.products.title.' + product),
                });
            }
        });
        return billing_desc;
    }),
    actions: {
        showOldBills: function () {
            this.set('showOldBills', true);
            return false;
        },
    },
});

export default SettingsBillingController;
