/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/controllers/register/service/adddetails.coffee.old
// Generated by CoffeeScript 2.6.1
var RegisterAdddetailsController,
    indexOf = [].indexOf;

import Ember from 'ember';

import validator from '../../../mixins/base-validation';

import config from '../../../config/environment';

RegisterAdddetailsController = Ember.Controller.extend(validator, {
    validationObject: 'model',
    cdn: Ember.inject.service(),
    intl: Ember.inject.service(),
    promo_start: '2016-11-23',
    promo_end: '2016-11-27',
    promocode: Em.computed(function () {
        if (
            this.get('promo_start') &&
            this.get('promo_end') &&
            moment().format('YYYY-MM-DD') >= this.get('promo_start') &&
            moment().format('YYYY-MM-DD') <= this.get('promo_end')
        ) {
            return '50BF2016';
        }
    }),
    queryParams: ['country', 'source', 'leadid', 'promo', 'email', 'deal'],
    setCountry: Em.observer(
        'country',
        'source',
        'leadid',
        'promo',
        'email',
        'model',
        'password',
        function () {
            var browser, country;
            if (this.get('model')) {
                this.set('model.country', this.get('country'));
                this.set('model.source', this.get('source'));
                this.set('model.leadid', this.get('leadid'));
                this.set('model.deal', this.get('deal'));
                if (this.get('promocode')) {
                    this.set('model.promocode', this.get('promocode'));
                } else {
                    this.set('model.promocode', this.get('promo'));
                }
                this.set('model.email', this.get('email'));
                this.set('model.password', this.get('password'));
                if (this.get('model.country') === void 0) {
                    browser = window.navigator.userLanguage || window.navigator.language;
                    country = browser.substring(0, 2).toLowerCase();
                    if (country === 'sv') {
                        country = 'se';
                    }
                    if (indexOf.call(this.get('countries').mapBy('id'), country) >= 0) {
                        return this.set('model.country', country);
                    }
                }
            }
        },
    ),
    validations: {
        lastname: {
            mandatory: true,
        },
        email: {
            mandatory: true,
            remote_email: true,
            email: true,
            maxLength: 80,
        },
        company: {
            mandatory: true,
        },
        vatin: {
            mandatory: true,
        },
        phone: {
            mandatory: true,
        },
        country: {
            mandatory: true,
        },
        password: {
            mandatory: true,
            minLength: 8,
        },
    },
    validationstate: Ember.Object.create(),
    countries: [
        {
            id: 'fi',
            value: 'Finland',
        },
        {
            id: 'se',
            value: 'Sweden',
        },
    ],
    //{id: "no", value: "Norway"}
    //{id: "dk", value: "Denmark"}
    //{id: "uk", value: "UK"}
    //{id: "us", value: "US"}
    hide_all_buttons: true,
    values: Ember.computed.alias('model'),
    modifyDomain: Em.observer('model.email', function () {
        var domain,
            domain_start,
            email,
            full_domain_added,
            set_domain,
            skipThese,
            splitted_domain,
            splitted_email;
        skipThese = ['gmail', 'yahoo', 'hotmail', 'outlook'];
        email = this.get('model.email');
        if (email == null) {
            return;
        }
        splitted_email = email.split('@');
        if (splitted_email.length < 2) {
            return;
        }
        domain = splitted_email[1];
        splitted_domain = domain.split('.');
        domain_start = splitted_domain[0];
        full_domain_added = splitted_domain.length > 1;
        if (full_domain_added) {
            set_domain = indexOf.call(skipThese, domain_start) >= 0 ? '' : domain;
        } else {
            set_domain = '';
        }
        return this.set('model.domain', set_domain);
    }),
    fieldSettings: Em.A({
        setOKtoAllFields: true,
        title: true,
    }),
    hideCancelButton: true,
    saveButtonText: Ember.computed(function () {
        return this.get('intl').t('general.next');
    }),
    customization: {
        text1: {
            template: 'login/register/company_info',
            hide_title: true,
        },
        text2: {
            template: 'login/register/promo_title',
            hide_title: true,
        },
    },
    terms_url: Ember.computed(function () {
        var cdn;
        cdn = this.get('cdn');
        switch (moment.locale()) {
            case 'fi':
                return config.termsAndConditions.fi;
            case 'sv':
            case 'se':
                return config.termsAndConditions.se;
            default:
                return config.termsAndConditions.en;
        }
    }),
    setValidationErrors: function () {
        var field, results, validations;
        validations = this.get('validations');
        results = [];
        for (field in validations) {
            results.push(this.setValidationError(field));
        }
        return results;
    },
    setValidationError: function (field) {
        var error, k, rule;
        if (!this.get('validationstate.' + field)) {
            this.set('validationstate.' + field, Ember.Object.create());
        }
        error = this.get('errors.' + field);
        if (!error) {
            this.set('validationstate.' + field + '.ok', true);
            this.set('validationstate.' + field + '.error', false);
        }
        for (k in error) {
            rule = error[k];
            this.set('validationstate.' + field + '.ok', false);
            this.set('validationstate.' + field + '.error', rule);
            return;
        }
    },
    actions: {
        autofill: function () {
            this.set('model.firstname', 'testfirstname');
            this.set('model.lastname', 'testlastname');
            this.set('model.email', Math.random().toString(36).substring(7) + '@mail.com');
            this.set('model.company', 'Test Company Ltd');
            this.set('model.vatin', '1234567-1');
            this.set('model.phone', '555-324-8272');
            this.set('model.country', 'fi');
            return this.set('model.password', '12345678');
        },
        // this is called when ever some field is modified
        change: function (field, value) {
            var promise, self;
            self = this;
            promise = this.validate([field]);
            //@setValidationErrors()

            return promise.then(() => {
                return this.setValidationError(field);
            });
        },
        setFilled: function (field) {
            return this.set(field + '_filled', true);
        },
        validateEmail: function (field) {
            var promise;
            this.set('model.email', this.get('model.email').toLowerCase());
            this.set(field + '_filled', true);
            promise = this.validate([field]);
            return promise.then(() => {
                return this.setValidationError(field);
            });
        },
        validatePassword: function (field) {
            var promise;
            this.set(field + '_filled', true);
            promise = this.validate([field]);
            return promise.then(() => {
                return this.setValidationError(field);
            });
        },
        clearErrors: function (field) {
            if (this.get('validationstate.' + field)) {
                return this.set('validationstate.' + field + '.error', false);
            }
        },
    },
});

export default RegisterAdddetailsController;
