import config from '../../../../config/environment';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import cdnService from 'tt4/injections/services/cdn';
import { action } from '@ember/object';
import moment from 'moment';

interface IMessAgesTypesUserConsentComponent {
    type: string;
    close(args0: string): void;
}

export default class MessAgesTypesUserConsentComponent extends Component<IMessAgesTypesUserConsentComponent> {
    @service protected cdn: cdnService;

    get terms_url() {
        switch (moment.locale()) {
            case 'fi':
                return config.termsAndConditions.fi;
            case 'sv':
            case 'se':
                return config.termsAndConditions.se;
            default:
                return config.termsAndConditions.en;
        }
    }

    @action
    closeModal() {
        if (this.args.close) {
            return this.args.close(this.args.type);
        }
    }
}
