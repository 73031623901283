/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/orientations/add/confirm/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var confirmController;

import Ember from 'ember';

import collector from '../../../../classes/collector';

confirmController = Ember.Controller.extend({
    //    needs: ["orientations/add"]
    addController: Ember.inject.controller('orientations/add'),
    indexController: Ember.inject.controller('orientations/add/index'),
    record: Ember.computed.alias('addController.record'),
    Collector: Ember.inject.service('collector-service'),
    intl: Em.inject.service(),
    dialogs: Em.inject.service(),
    saving: false,
    passBlockLoading: false,
    validPassBlocks: Ember.A(), // helper array to store the pass blocks by users
    passDatesAlreadySet: false, // helper variable to check if the pass dates have been set
    fileField: Ember.computed('record.ori_site.file', function () {
        var field;
        field = this.get('Collector').field('project', 'file');
        field.value = this.get('record.ori_site.file');
        this.set('record.file', this.get('record.ori_site.file'));
        return field;
    }),

    placeHolder: Ember.computed('validPassBlocks.[]', function () {
        if (this.get('validPassBlocks').length === 1) {
            return this.get('intl').t('select.all');
        }
        return this.get('intl').t('orientations.validation.select_rest');
    }),

    // computed property to get the users that are not selected to any pass block
    unselectedUsers: Ember.computed('addController.users', function () {
        const users = this.get('addController.userRecords');
        let userArray = [];
        for (let user of users) {
            userArray.push({
                id: user.get('id'),
                name: user.get('firstname') + ' ' + user.get('lastname'),
            });
        }
        let selectedUsers = [];
        // now we have the users, let's add the selected users to the selectedUsers array
        for (let block of this.get('validPassBlocks')) {
            for (let user of block.users) {
                selectedUsers.push(user.id);
            }
        }
        // now we have the selected users, let's remove them from the userArray
        userArray = userArray.filter((user) => {
            return !selectedUsers.includes(user.id);
        });
        return userArray;
    }),
    // if false, do not show the button to add more blocks
    areThereUnselectedUsers: Ember.computed('unselectedUsers', 'validPassBlocks.[]', function () {
        // if no user-blocks are added, we need to use special validation here that skips zero users check
        // because zero user-blocks is valid in the case where we are not adding passes to any users
        // and the result of areUserPassesValid has to be inverted so that this function returns true if there are unselected users
        // normally areUserPassesValid would return false if there are unselected users
        const ret = !this.areUserPassesValid(true);
        // if all users passes are not valid, we have still unselected users
        return ret;
    }),

    extras: Ember.computed(function () {
        var extras, extras_tmp;
        extras_tmp = collector.forms.orientation.objects;
        extras = [];
        $.each(extras_tmp, function (index, obj) {
            if (obj.type !== 'checkboxgroup') {
                if (index.indexOf('addon_') >= 0) {
                    obj.ext = index;
                    return extras.push(obj);
                }
            }
        });
        return extras;
    }),
    passStart: moment().format('YYYY-MM-DD'),
    unvalid: Em.A([]),
    setPassDates: function () {
        if (this.Collector.testNeeds(['project.proj_end'])) {
            if (this.get('record.ori_site.proj_end')) {
                return this.setPassDatesTo(null, this.get('record.ori_site.proj_end'));
            } else {
                return this.setPassDatesTo();
            }
        } else {
            return this.setPassDatesTo();
        }
    },
    setPassDatesTo: function (start, end) {
        if (start) {
            this.set('record.pass_start', moment(start).format('YYYY-MM-DD'));
        } else {
            this.set('record.pass_start', moment().format('YYYY-MM-DD'));
        }
        if (end) {
            return this.set('record.pass_end', moment(end).format('YYYY-MM-DD'));
        } else {
            return this.set('record.pass_end', moment().add(1, 'months').format('YYYY-MM-DD'));
        }
    },
    clearPassDates: function () {
        if (this.get('showPassDates')) {
            this.set('record.pass_start', null);
            this.set('record.pass_end', null);
            return this.set('showPassDates', false);
        }
    },
    checkTVEmployerStatus: function (employer) {
        var employerId;
        employerId = Ember.get(employer, 'emp_id');
        if (!employerId) {
            return;
        }
        return this.get('Collector').ccapi({
            url: 'tvemployer',
            data: {
                tunnus: employerId,
            },
            type: 'GET',
            success: function (data) {
                if (!data.error) {
                    data = data[0];
                }
                employer.set('date_checked', moment().format('YYYY-MM-DD'));
                if (data != null && data.status && !data.error) {
                    employer.set('tv_status', data.status.toLowerCase());
                    employer.set('archive_code', data.archive_code);
                } else {
                    employer.set('tv_status', 'ei_loydy');
                    employer.set('archive_code', '');
                }
                return employer.save();
            },
        });
    },
    actions: {
        removePassBlock: function (block_id) {
            // find correct block by id and filter it out
            this.set(
                'validPassBlocks',
                this.get('validPassBlocks').filter((block) => block.id !== block_id),
            );
            // notify unselectedUsers to update the selected users
            this.notifyPropertyChange('unselectedUsers');
        },
        addNewPassPanel: async function () {
            this.set('passBlockLoading', true);
            if (!this.passDatesAlreadySet) {
                await this.getPassDatesFromProject();
            } else {
                this.set('passDatesAlreadySet', true);
            }
            this.get('validPassBlocks').pushObject({
                id: this.get('validPassBlocks').length,
                pass_start: this.get('record.pass_start'),
                pass_end: this.get('record.pass_end'),
                extra_info: null,
                users: [],
            });
            // if there are only one user unselected, add it to a new pass block
            if (this.get('unselectedUsers').length === 1) {
                this.send('addUserToPassBlock', this.get('validPassBlocks').length - 1, [
                    this.get('unselectedUsers')[0],
                ]);
            }
            this.set('passBlockLoading', false);
        },
        addUserToPassBlock: function (block_id, users) {
            this.set(`validPassBlocks.${block_id}.users`, users);
            // notify unselectedUsers to update the selected users
            this.notifyPropertyChange('unselectedUsers');
        },
        async save(row) {
            this.set('saving', true);
            var dummy, k, mandatory, records, self, store, users, v, valid;
            self = this;
            mandatory = this.get('addController.mandatoryArr');
            valid = true;
            this.set('unvalid', Em.A([]));

            for (k in mandatory) {
                v = mandatory[k];
                if (
                    !row.get(k) &&
                    k !== 'ori_user' &&
                    k !== 'ori_site' &&
                    k !== 'ori_date' &&
                    k !== 'ori_place' &&
                    k !== 'sign_emp_img' &&
                    k !== 'sign_per_img'
                ) {
                    self.get('unvalid').pushObject(
                        self.get('intl').t('orientations.validation.' + k),
                    );
                    valid = false;
                }
            }
            // go through all the pass blocks and check that the pass start and end dates are valid
            for (let block of this.get('validPassBlocks')) {
                if (moment(block.pass_start).isAfter(block.pass_end)) {
                    self.get('unvalid').pushObject(
                        self.get('intl').t('orientations.validation.pass_start_before_end'),
                    );
                    valid = false;
                }
            }

            if (valid) {
                this.set('addController.disableLinks', true);
                users = this.get('addController.users');
                store = this.get('store');
                dummy = row.serialize();
                dummy.ori_site = store.peekRecord('project', dummy.ori_site);
                records = Em.A([]);

                for (let id of users) {
                    try {
                        let oriuser = await self
                            .get('Collector')
                            .findFormRelations('orientation', 'ori_user', {
                                id: id,
                            });

                        var record, ref, user;
                        user = oriuser[0];
                        dummy.ori_user = user;
                        if (self.get('Collector').fieldExists('orientation', 'parent')) {
                            dummy.parent = self.get('addController.selected_parents')[id];
                        }
                        record = store.createRecord('orientation', dummy);

                        // get the pass dates for the user
                        let passData = self.getPassDataForUser(user);
                        if (passData) {
                            record.set('pass_start', passData.pass_start);
                            record.set('pass_end', passData.pass_end);
                            record.set('extra_info', passData.extra_info);
                        }
                        records.pushObject(record);
                    } catch (error) {
                        console.error('Error occurred while processing user:', error);
                    }
                }
            }
            // if there are no unselected users, go to the next step
            if (valid && this.areUserPassesValid()) {
                if (this.get('Collector').testNeedsOne('employer')) {
                    try {
                        let queriedUsers = await store.query('user', {
                            id: users.join(','),
                        });

                        var usersWithUniqueEmployers;
                        usersWithUniqueEmployers = queriedUsers.uniqBy('employer.emp_id');

                        for (let user of usersWithUniqueEmployers) {
                            if (!user.get('employer.id')) {
                                continue;
                            }
                            try {
                                let employer = await store.findRecord(
                                    'employer',
                                    user.get('employer.id'),
                                );
                                await this.checkTVEmployerStatus(employer);
                            } catch (error) {
                                console.error('Error fetching or checking employer:', error);
                            }
                        }
                    } catch (error) {
                        console.error('Error occurred while querying users:', error);
                    }
                }
                self.set('addController.orientations', records);
                self.clearPassDates();

                if (
                    (ref = self.get('addController.projectHasLocks')) != null ? ref._result : void 0
                ) {
                    self.transitionToRoute('orientations.add.add-passes');
                } else {
                    self.transitionToRoute('orientations.add.signatures');
                    row.rollbackAttributes();
                }
            } else if (!this.areUserPassesValid()) {
                // add validation error to unvalid-array
                self.get('unvalid').pushObject(
                    self.get('intl').t('orientations.validation.unselected_users'),
                );
            }
            this.set('saving', false);
        },
        setFile: function (file, field) {
            return this.set('record.file', file);
        },
    },
    getPassDatesFromProject: async function () {
        // Mark the function as async
        var oriProjId;
        if (Modernizr.inputtypes.date) {
            oriProjId = this.get('indexController.record.ori_site.id');

            if (this.Collector.testNeeds(['employer', 'urakkaraportointi'])) {
                try {
                    const user = await this.store.findRecord(
                        'user',
                        this.get('addController.users.firstObject'),
                        { reload: true },
                    );
                    const contracts = await this.store.query('urakkaraportointi', {
                        contract_employer: user.get('employer.id'),
                    });

                    const contractEndDateFound = contracts
                        .sortBy('contract_month')
                        .reverseObjects()
                        .any((contract) => {
                            const contrProjId = contract.get('contract_project.id');
                            if (contrProjId === oriProjId) {
                                this.setPassDatesTo(null, contract.get('contract_end_date'));
                            }
                            return contrProjId === oriProjId;
                        });

                    if (!contractEndDateFound) {
                        this.setPassDates();
                    }
                } catch (error) {
                    console.error('Error occurred while fetching data:', error);
                }
            } else {
                this.setPassDates();
            }
        } else {
            return false;
        }
    },
    // helper function to get the pass dates for the user from the validPassBlocks array
    getPassDataForUser(user) {
        for (let block of this.get('validPassBlocks')) {
            if (block.users.some((u) => u.id === user.id)) {
                return {
                    pass_start: block.pass_start,
                    pass_end: block.pass_end,
                    extra_info: block.extra_info,
                };
            }
        }
        // user was not found from the validPassBlocks array so we take the first block that doesn't have any users and return its pass dates
        for (let block of this.get('validPassBlocks')) {
            if (block.users.length === 0) {
                return {
                    pass_start: block.pass_start,
                    pass_end: block.pass_end,
                    extra_info: block.extra_info,
                };
            }
        }
    },

    // Passes are valid if:
    // 1. No blocks has been added (no need to add passes to any users, special validation)
    // 2. If there is only one block without users
    // 3. if there are no blocks without users, number of users combined in the blocks is the same as the number of users in the addController.users array
    areUserPassesValid: function (skipZeroCheck = false) {
        if (!skipZeroCheck && this.get('validPassBlocks').length === 0) {
            return true;
        }
        let blocksWithoutUsers = this.get('validPassBlocks').filter(
            (block) => block.users.length === 0,
        );
        if (blocksWithoutUsers.length > 1) {
            return false;
        }
        if (blocksWithoutUsers.length === 1) {
            return true;
        }
        let userCount = this.get('validPassBlocks').reduce(
            (acc, block) => acc + block.users.length,
            0,
        );
        return userCount === this.get('addController.users').length;
    },
});

export default confirmController;
