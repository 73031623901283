import Component from '@glimmer/component';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action, set } from '@ember/object';
import { isEmpty } from '@ember/utils';

export default class AbsenteesComponent extends Component {
    @service store;
    @service intl;
    @service('collector-service') Collector;
    @service localstorage;
    @service evented;
    @service analytics;

    @tracked data;
    @tracked dateRange;
    @tracked currentSalaryPeriod;
    @tracked layout;
    @tracked ganttData;
    @tracked dateRanges;

    get styleNamespace() {
        return podNames['dash-board/widgets/absentees'];
    }

    get colorFields() {
        return ['abcensetype.id'];
    }

    get popupFields() {
        return ['dateRange', 'userName', 'absencetype', 'description'];
    }

    get absenceTypes() {
        let field = this.Collector.field('abcense', 'abcensetype');
        if (field) {
            field.value = this.abcensetype;
            field.prompt = 'general.all_absencetypes';
        }
        return field;
    }

    async getDateRanges() {
        if (!this.currentSalaryPeriod) await this.setCurrentPeriod();
        return [
            {
                key: 'this_week',
                id:
                    moment().startOf('isoWeek').format('YYYY-MM-DD') +
                    '_' +
                    moment().endOf('isoWeek').format('YYYY-MM-DD'),
                name: this.intl.t('general.period.this_week'),
            },
            {
                key: 'this_and_next_week',
                id:
                    moment().startOf('isoWeek').format('YYYY-MM-DD') +
                    '_' +
                    moment().add(1, 'week').endOf('isoWeek').format('YYYY-MM-DD'),
                name: this.intl.t('general.period.this_and_next_week'),
            },
            {
                key: 'this_month',
                id:
                    moment().startOf('month').format('YYYY-MM-DD') +
                    '_' +
                    moment().endOf('month').format('YYYY-MM-DD'),
                name: this.intl.t('general.period.this_month'),
            },
            {
                key: 'next_month',
                id:
                    moment().add(1, 'month').startOf('month').format('YYYY-MM-DD') +
                    '_' +
                    moment().add(1, 'month').endOf('month').format('YYYY-MM-DD'),
                name: this.intl.t('general.period.next_month'),
            },
            {
                key: 'this_salaryperiod',
                id: this.currentSalaryPeriod,
                name: this.intl.t('general.period.this_salaryperiod'),
            },
        ];
    }

    // Fetch absences for all users in batches
    async fetchAllAbsencesInBatches() {
        const allAbsences = [];
        const limit = 100; // Fetch 100 records at a time
        let offset = 0;
        let hasMore = true;

        while (hasMore) {
            let batch = await this.store.query('abcense', {
                startdate_enddate: this.dateRanges.filterBy('key', this.dateRange.key)[0]['id'],
                order: 'startdate,enddate',
                abcensetype: this.abcensetype,
                sideload: true,
                limit: limit,
                offset: offset,
            });

            allAbsences.push(...batch.toArray());

            if (batch.length < limit) {
                hasMore = false;
            } else {
                offset += limit;
            }
        }

        return allAbsences;
    }

    async getAbsences() {
        if (!this.dateRanges) await this.setDateRanges();

        const absences = await this.fetchAllAbsencesInBatches();
        const formattedData = this.formatAbsences(absences);
        this.ganttData = this.absencesByuser(formattedData);
        return formattedData;
    }

    async setCurrentPeriod() {
        let salaryPeriods = this.store.peekAll('wage-period');
        if (isEmpty(salaryPeriods)) salaryPeriods = await this.store.findAll('wage-period');
        if (isEmpty(salaryPeriods))
            return (this.currentSalaryPeriod =
                moment().startOf('month').format('YYYY-MM-DD') +
                '_' +
                moment().endOf('month').format('YYYY-MM-DD'));

        const currentDate = moment().format('YYYY-MM-DD');
        const currentPeriods = salaryPeriods.filter((period) => {
            return period.period_start_date <= currentDate && period.period_end_date >= currentDate;
        });
        if (isEmpty(currentPeriods))
            return (this.currentSalaryPeriod =
                moment().startOf('month').format('YYYY-MM-DD') +
                '_' +
                moment().endOf('month').format('YYYY-MM-DD'));
        // Just set first that is found
        this.currentSalaryPeriod =
            currentPeriods[0].period_start_date + '_' + currentPeriods[0].period_end_date;
    }

    constructor() {
        super(...arguments);
        this.data = this.getAbsences();
        this.form = 'absence';
        this.headers = ['userName', 'absencetype', 'start', 'end'];
        this.layout = this.getFilter('absentee_widget_layout', 'table');
        this.evented.on('storeEvent', this, 'onStoreEvent');
        this.evented.on('onResumed', this, 'onResumed');
    }

    @action
    filterchanged(value, field) {
        if (field && typeof field === 'string') {
            this.analytics.trackEvent({
                category: 'Dashboard',
                action: `Change ${field}`,
                label: 'Absentees widget',
            });
            if (!value) set(this, field, null);
            else set(this, field, value.id);
        }
        this.data = this.getAbsences();
    }

    @action
    changeDate(value) {
        if (value) this.dateRange = value;
        this.analytics.trackEvent({
            category: 'Dashboard',
            action: `Change date range`,
            label: 'Absentees widget',
        });
        this.setFilterToLS('absentee_widget_filter', this.dateRange);
        this.data = this.getAbsences();
    }

    @action
    changeLayout(layout) {
        this.layout = layout;
        this.analytics.trackEvent({
            category: 'Dashboard',
            action: `Use ${layout} layout`,
            label: 'Absentees widget',
        });
        this.setFilterToLS('absentee_widget_layout', layout);
    }

    async setDateRanges() {
        this.dateRanges = await this.getDateRanges();
        let lsRange = this.getFilter('absentee_widget_filter', this.dateRanges[0]);
        if (!lsRange.key) this.dateRange = this.dateRanges[0];
        else this.dateRange = lsRange;
    }

    setFilterToLS(key, filter) {
        this.localstorage.setItem(key, filter);
    }

    getFilter(key, defaultValue) {
        let LSFilter = this.localstorage.getItem(key);
        return LSFilter ? LSFilter : defaultValue;
    }

    formatAbsences(absences) {
        return absences.map((item) => {
            set(item, 'start', moment(item.startdate).format('L'));
            set(item, 'end', moment(item.enddate).format('L'));
            set(
                item,
                'dateRange',
                moment(item.startdate).format('DD.MM') +
                    ' - ' +
                    moment(item.enddate).format('DD.MM'),
            );
            set(item, 'absencetype', item.abcensetype.name);
            set(item, 'userName', item.user.firstname + ' ' + item.user.lastname);
            return item;
        });
    }

    absencesByuser(absences) {
        let users = absences.reduce((acc, item) => {
            let arr = [...acc, item.user];
            return [...new Set(arr)];
        }, []);

        return users.map((user) => {
            let arr = [];
            let rows = this.formatganttData(absences.filterBy('user.id', user.id));
            arr.rows = rows;
            arr.mainHeader = user.firstname + ' ' + user.lastname;
            return arr;
        });
    }

    formatganttData(rows) {
        return rows.reduce((acc, item) => {
            const date = item.startdate;
            if (!acc[date]) acc[date] = [];

            if (item.enddate) {
                let i = 1;
                const moment_date = moment(date);
                const diff = moment(item.enddate).diff(moment_date, 'day');
                acc[date].push(item);
                while (i <= diff) {
                    const new_date = moment_date.add(1, 'day').format('YYYY-MM-DD');
                    if (!acc[new_date]) acc[new_date] = [];
                    acc[new_date].push(item);
                    i++;
                }
            } else {
                acc[date].push(item);
            }
            return acc;
        }, {});
    }
    willDestroy() {
        this.evented.off('storeEvent', this, 'onStoreEvent');
        this.evented.off('onResumed', this, 'onResumed');
    }

    onStoreEvent(params) {
        if (params.type === 'abcense') this.data = this.getAbsences();
    }

    async onResumed(params) {
        // do not refresh if resume event was triggered last time no more than 5 minutes ago
        // params.force is used by manual refresh button and autorefresh
        if (params.duration < 5 * 60 && !params.force) return;
        if (this.currentSalaryPeriod) {
            // If dashboard is open for a long time, we have to update current period if its end date is past current date
            const splitCurrent = this.currentSalaryPeriod.split('_');
            if (splitCurrent[1] < moment().format('YYYY-MM-DD')) await this.setCurrentPeriod();
        }
        this.dateRanges = await this.getDateRanges();
        if (this.dateRange.key) {
            this.setFilterToLS(
                'absentee_widget_filter',
                this.dateRanges.filterBy('key', this.dateRange.key)[0],
            );
            this.dateRange = this.dateRanges.filterBy('key', this.dateRange.key)[0];
        } else {
            this.dateRange = this.dateRanges[0];
        }
        this.data = this.getAbsences();
    }
}
