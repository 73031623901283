/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/site-safety/report/mvr/data/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var SiteSafetyMvrDataController;

import Ember from 'ember';

import GeneralReportController from 'tt4/controllers/general-report';

import formattedMixin from 'tt4/mixins/sitesafety-formatted-export';

import { computed } from '@ember/object';

SiteSafetyMvrDataController = GeneralReportController.extend(formattedMixin, {
    intl: Em.inject.service(),
    flags: Em.inject.service(),
    dialogs: Em.inject.service(),
    queryParams: ['project', 'date', 'page', 'groupBy'],
    arrangedContent: Ember.computed('model.[]', function () {
        return this.get('content').sortBy('date');
    }),
    date: moment().subtract(3, 'months').format('YYYY-MM-DD') + '_' + moment().format('YYYY-MM-DD'),
    form: 'mvr',
    filters: computed(function () {
        return Em.A([
            Em.Object.create({
                field: 'project',
            }),
            Em.Object.create({
                field: 'date',
            }),
            Em.Object.create({
                type: 'searchbox',
                bindto: 'q',
            }),
            Em.Object.create({
                field: 'groupBy',
                type: 'groupByFilter',
            }),
        ]);
    }),
    init: function () {
        this._super();
        if (!this.get('flags').test('ember-movenium-form-report-measurements')) {
            return this.addRowActions();
        }
    },
    formatter: function (column, value) {
        if (column === 'mvr_rating') {
            if (value > 95) {
                return new Ember.String.htmlSafe(
                    '<span class="label-m label-m-approved">' + value + ' %</span>',
                );
            } else if (value > 90) {
                return new Ember.String.htmlSafe(
                    '<span class="label-m label-m-warning">' + value + ' %</span>',
                );
            } else {
                return new Ember.String.htmlSafe(
                    '<span class="label-m label-m-open">' + value + ' %</span>',
                );
            }
        }
        return false;
    },
    showColumns: [
        'status',
        'project',
        'user',
        'date',
        'mvr_rating',
        'equipment_plus',
        'order_plus',
        'protection_plus',
        'road_plus',
        'working_plus',
        'foreman_sign',
        'worker_sign',
    ],
    settings: Em.Object.create({
        rowSettings: Em.Object.create({
            editRoute: {
                dynamic: true,
                route: 'site_safety.measurement.add.measurement',
                fields: ['id', 'constructor.modelName', 'project.id'],
            },
            historyRoute: 'site_safety.mvr.history',
            //hideEdit: true
            hideHistory: true,
            disableStatus: 'approved',
        }),
        sortByColumn: 'date-',
    }),
    addRowActions: function () {
        var fields, intl;
        intl = this.get('intl');
        if (this.Collector == null) {
            return;
        }
        fields = this.Collector.fields('mvr');
        return this.set('settings.rowActions', [
            {
                text: 'site_safety.formatted_pdf_export',
                action: (self, items) => {
                    return this.createMeasurementPdf(fields, items);
                },
            },
        ]);
    },
    actions: {
        transitionToEditRoute: function (row) {
            return this.transitionToRoute(
                'site_safety.measurement.add.measurement',
                row.id,
                'mvr',
                row.project.id,
            );
        },
        filterChangeAction: function (value, field) {
            if (Ember.typeOf(value) === 'object' || Ember.typeOf(value) === 'instance') {
                return this.set(field, value.id);
            } else {
                return this.set(field, value);
            }
        },
        createSpecialPdf: function (selected) {
            // if selected is not defined, use dialogs-service to show the user that they need to select something
            if (selected == null || selected.length === 0) {
                return this.get('dialogs').alert(
                    this.intl.t('site_safety.formatted_pdf_export_select'),
                );
            } else {
                let fields;
                fields = this.Collector.fields('mvr');
                return this.createMeasurementPdf(fields, selected);
            }
        },
    },
});

export default SiteSafetyMvrDataController;
